import Axios from "axios";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import filterQueryParams from "@/services/filterQueryParams";
import Notification from "@/entity/Notification";

export default class NotificationsService {
  public static async find(
    query: TableQuery
  ): Promise<ListResponseEntity<Notification>> {
    const response = await Axios.get("/api/v2/notifications", {
      params: {
        ...filterQueryParams(query),
      },
    });

    return response.data;
  }

  public static async dismiss(
    notificationIds: number[],
    unRead = false
  ): Promise<any> {
    const response = await Axios.post(
      "/api/v2/notifications/dismiss",
      {},
      {
        params: {
          ids: notificationIds.toString(),
          unread: unRead,
        },
      }
    );
    return response.data;
  }

  public static async important(
    notificationIds: number[],
    regular = false
  ): Promise<any> {
    const response = await Axios.post(
      "/api/v2/notifications/important",
      {},
      {
        params: {
          ids: notificationIds.toString(),
          regular,
        },
      }
    );
    return response.data;
  }

  public static async dismissAll(): Promise<any> {
    const response = await Axios.post(
      "/api/v2/notifications/dismiss",
      {},
      {
        params: {
          all: true,
        },
      }
    );
    return response.data;
  }

  public static async duplicateExposeEditEmail(id: number): Promise<any> {
    const response = await Axios.post(
      `/api/v2/notifications/${id}/duplicate-expose-edit-email`
    );
    return response.data;
  }
}
